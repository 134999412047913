body {
  font-family: Verdana, sans-serif;
}

.MuiButton-root {
  text-transform: none !important;
}

.leaflet-div-icon {
  border: none;
  background: none;
}

::-webkit-scrollbar {
  width: 5px;
  background: transparent;
}

::-webkit-scrollbar-thumb {
  background: #a3a3a3;
}

/* Sec; #00FF99 */
/* .btn {
  margin: 8px;
  border-radius: 0px;
  background-color: transparent;
  font-size: 18px;
  padding: 4px 24px 4px 24px;
  color: #00FF99;
  border: 2px solid #00FF99;
  padding: 12px 24px 12px 24px;
  font-family: Verdana, sans-serif;
  cursor: pointer;
} */
.btn {
  border-radius: 3px;
  background-color: transparent;
  font-size: 15px;
  color: #00FF99;
  border: 2px solid #00FF99;
  font-family: Verdana, sans-serif;
  cursor: pointer;
}

.btn:hover {
  /* background-color: #111 !important; */
  /* border: 2px solid #00FF99; */
}

.btn-inv {
  margin: 8px;
  border-radius: 0px;
  background-color: #00FF99;
  background: linear-gradient(135deg, #00FF99, #94e6ff);
  font-size: 18px;
  padding: 4px 24px 4px 24px;
  color: #333;
  border: 2px solid #00FF99;
  padding: 12px 24px 12px 24px;
  font-family: Verdana, sans-serif;
  cursor: pointer;
}

btn-inv:disabled {
  background-color: #333;

}

.btn-small {
  font-size: 15px;
  background-color: #00FF99;
  color: #111;
  padding: 4px 8px;
  margin-top: 4px;
}

.text-input {
  padding: 8px;
  width: 100%;
  background-color: #333;
  border: 1px solid #00FF99;
  border-radius: 2px !important;
  font-family: Verdana, sans-serif;
  font-size: medium;
  color: #d4d4d4;
}

.text-input:active,
.text-input:focus-visible,
.text-input:focus,
.text-input:hover {
  box-shadow: none;
  outline: none;
  background-color: #111;
}

.select-input {
  border-radius: 2px;
  background-color: #333;
  color: #d4d4d4;
  border: 1px solid #00FF99;
  width: 100%;
}

.select-input:active,
.select-input:focus-visible,
.select-input:focus,
.select-input:hover {
  box-shadow: none;
  outline: none;
  background-color: #111;
  border: 1px solid #00FF99;
}

.header {
  font-family: Verdana, sans-serif;
  color: #00FF99;
  text-transform: uppercase;
  font-weight: bolder;
  background: linear-gradient(135deg, #00FF99, #94e6ff);
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
}

.content-text {
  font-family: Verdana, sans-serif !important;
  font-weight: lighter !important;
  text-align: left;
  padding: 8px;
  color: rgb(153, 153, 153) !important;
}

.content-text-about {
  font-family: Verdana, sans-serif !important;
  font-weight: lighter !important;
  text-align: justify;
  padding: 8px;
  color: rgb(153, 153, 153) !important;
}

.content-text-3 {
  font-family: Verdana, sans-serif !important;
  font-weight: lighter !important;

  text-align: left;
  padding: 8px;
  color: rgb(46, 46, 46) !important;
}

.content-text-details {
  font-family: Verdana, sans-serif !important;
  font-weight: lighter !important;

  text-align: left;
  padding: 4px;
  color: rgb(46, 46, 46) !important;
}

.content-text-details-2 {
  font-family: Verdana, sans-serif !important;
  font-weight: lighter !important;

  text-align: left;
  padding: 4px;
  color: rgb(200, 200, 200) !important;
}

.header-2-small {
  font-family: Verdana, sans-serif !important;

  text-align: left !important;
  color: rgb(243, 243, 243) !important;
  text-transform: capitalize !important;
  font-weight: regular !important;
}

.header-3-small {
  font-family: Verdana, sans-serif !important;
  text-align: center !important;
  color: #00FF99 !important;
  text-transform: capitalize !important;
  font-weight: regular !important;
}

.header-4-small {
  font-size: 20px;
  font-family: Verdana, sans-serif !important;
  text-align: center;
  color: rgb(243, 243, 243) !important;
  text-transform: none;
  font-weight: regular !important;
}

.footer-text {
  font-family: Verdana, sans-serif !important;
  font-weight: lighter !important;

  text-align: center;
  padding: 8px;
  color: rgb(223, 223, 223) !important;
}

.content-card {
  padding: 32px;
  margin: auto;
  background-color: #000000aa !important;
  backdrop-filter: blur(6px);
  min-height: 100vh;
}

.content-card-video {
  background-color: #000000aa !important;
  backdrop-filter: blur(6px);
}

.sub-header {
  font-family: Verdana, sans-serif !important;
  font-weight: lighter !important;
  color: rgb(243, 243, 243) !important;

}

.acc-li {
  padding: 12px !important;
  background: #191919 !important;
  box-shadow: none !important;
}

.acc-li:hover {
  background: linear-gradient(135deg, #07b16d88, #71aec088) !important;
  color: white !important;
}

/* .acc-container:before {
  position: relative;
  top: -1rem;
  z-index: 100;
  left: 0rem;
  width: 300px;
  height: 200px;
  background: linear-gradient(to left, #55b4d4, #5758e0);
  content: '';
} */

.header-2 {
  font-family: Verdana, sans-serif !important;

  color: rgb(243, 243, 243) !important;
  text-transform: capitalize !important;
  font-weight: regular !important;
}

.header-2-dark {
  font-family: Verdana, sans-serif !important;

  color: rgb(43, 43, 43) !important;
  text-transform: capitalize !important;
  font-weight: regular !important;
}

.header-3 {
  font-family: Verdana, sans-serif !important;

  color: rgb(243, 243, 243) !important;
  text-transform: capitalize !important;
  background: linear-gradient(135deg, #00FF99, #94e6ff);
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
  font-weight: bolder !important;
  padding-bottom: 32px;
}

.content-text-2 {
  font-family: Verdana, sans-serif !important;

  padding: 8px;
  color: rgb(153, 153, 153) !important;
  background: linear-gradient(135deg, #00FF99, #94e6ff);
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
  font-weight: bolder !important;
}



.content-text-2-about {
  font-family: Verdana, sans-serif !important;

  padding: 8px;
  color: rgb(153, 153, 153) !important;
  background: linear-gradient(135deg, #00FF99, #94e6ff);
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
  font-weight: bolder !important;
}

.contact-card {
  background-color: #191919;
  border-radius: 0px;
  padding: 32px;
  min-height: 300px;
  margin: auto;
}

.contact-icon {
  color: #00FF99 !important;

}

.center {
  text-align: center;
}

.radio-label {
  padding: 16px 16px 16px 0px;
  width: auto;
  border: 0px solid #00FF99;
  border-radius: 4px;
  font-family: Verdana, sans-serif;

  color: #d4d4d4;
}

.input-label {
  padding: 4px;
  width: auto;
  border: 0px solid #00FF99;
  border-radius: 4px;
  font-family: Verdana, sans-serif;

  color: #d4d4d4;
}

a {
  text-decoration: none;
  color: inherit;
  margin: auto;
}

.bg-dark {
  background-color: #000000aa !important;
}

.accordion-button:not(.collapsed) {
  background-color: #333;
  box-shadow: none;
}

.accordion-button {
  background-color: #191919;
}

.accordion-button:hover {
  background: linear-gradient(135deg, #07b16d88, #71aec088) !important;
  color: white !important;
}

.accordion-item {
  border: none;
}

.accordion-button:focus {
  border: none;
  box-shadow: none;
}

.form-control:focus {
  box-shadow: none;
  border: inherit;
}

.address-search {
  position: relative;
  border-radius: 0px;
  background-color: transparent !important;
  margin-left: 0px;
  padding: 16px;
  width: 100%;
  border: 2px solid #00FF99 !important;
  border-left: none !important;
  color: #00FF99 !important;
}

.address-search::placeholder {
  color: #00FF99;
}

.address-search-container:hover {
  background-color: #00000088 !important;
}

.address-search-icon>div {
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

.address-search-icon {
  border-radius: 0px;
  border: 2px solid #00FF99 !important;
  border-right: none !important;
  background-color: transparent !important;
  color: #00FF99;

}

.layer-list-container {
  width: calc(100%);
  justify-content: space-between;
  display: flex;
  flex-wrap: wrap;
  box-sizing: border-box;
}

.subtitle2 {
  margin: 0;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 500;

  line-height: 1.57;
  letter-spacing: 0.00714em;
}

.caption {
  margin: 0;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;

  line-height: 1.66;
  letter-spacing: 0.03333em;
}


@media screen and (min-width: 320px) {
  .header {
    font-size: 2em;
  }

  .sub-header {
    font-size: 18px !important;
  }

  .caption {
    font-size: 0.75rem;
  }

  .subtitle2 {
    font-size: 0.875rem;
  }

  .address-search-icon {
    font-size: 28px;
  }

  .input-label {
    font-size: medium;
  }

  .radio-label {
    font-size: medium;
  }

  .contact-icon {
    font-size: 44px !important;
  }

  .content-text-2 {
    font-size: 20px !important;
  }

  .header-3 {
    font-size: 24px !important;
  }

  .header-2-dark {
    font-size: 24px !important;
  }

  .header-2 {
    font-size: 32px !important;
  }

  .footer-text {
    font-size: 16px !important;
  }

  .header-3-small {
    font-size: 18px !important;
  }

  .header-2-small {
    font-size: 24px !important;
  }

  .content-text-details-2 {
    font-size: 14px !important;
  }

  .content-text-details {
    font-size: 14px !important;
  }

  .content-text-3 {
    font-size: 20px !important;
  }

  .content-text {
    font-size: 20px !important;
  }

  .text-input {
    font-size: medium;
  }

  .btn-small {
    font-size: 15px;
  }

  .btn-inv {
    font-size: 18px;
  }

  .btn {
    font-size: 15px;
  }
}

@media screen and (min-width: 736px) {
  .header {
    font-size: 2.5em;
  }

  .sub-header {
    font-size: 18px !important;
  }

  .caption {
    font-size: 0.75rem;
  }

  .subtitle2 {
    font-size: 0.875rem;
  }

  .address-search-icon {
    font-size: 28px;
  }

  .input-label {
    font-size: medium;
  }

  .radio-label {
    font-size: medium;
  }

  .contact-icon {
    font-size: 44px !important;
  }

  .content-text-2 {
    font-size: 20px !important;
  }

  .header-3 {
    font-size: 28px !important;
  }

  .header-2-dark {
    font-size: 24px !important;
  }

  .header-2 {
    font-size: 32px !important;
  }

  .footer-text {
    font-size: 16px !important;
  }

  .header-3-small {
    font-size: 20px !important;
  }

  .header-2-small {
    font-size: 24px !important;
  }

  .content-text-details-2 {
    font-size: 14px !important;
  }

  .content-text-details {
    font-size: 14px !important;
  }

  .content-text-3 {
    font-size: 20px !important;
  }

  .content-text {
    font-size: 20px !important;
  }

  .text-input {
    font-size: medium;
  }

  .btn-small {
    font-size: 15px;
  }

  .btn-inv {
    font-size: 18px;
  }

  .btn {
    font-size: 15px;
  }
}

@media screen and (min-width: 1024px) {
  .header {
    font-size: 3em;
  }

  .sub-header {
    font-size: 20px !important;
  }

  .caption {
    font-size: 0.75rem;
  }

  .subtitle2 {
    font-size: 0.875rem;
  }

  .address-search-icon {
    font-size: 28px;
  }

  .input-label {
    font-size: medium;
  }

  .radio-label {
    font-size: medium;
  }

  .contact-icon {
    font-size: 44px !important;
  }

  .content-text-2 {
    font-size: 20px !important;
  }

  .header-3 {
    font-size: 32px !important;
  }

  .header-2-dark {
    font-size: 24px !important;
  }

  .header-2 {
    font-size: 32px !important;
  }

  .footer-text {
    font-size: 16px !important;
  }

  .header-3-small {
    font-size: 20px !important;
  }

  .header-2-small {
    font-size: 24px !important;
  }

  .content-text-details-2 {
    font-size: 14px !important;
  }

  .content-text-details {
    font-size: 14px !important;
  }

  .content-text-3 {
    font-size: 20px !important;
  }

  .content-text {
    font-size: 20px !important;
  }

  .text-input {
    font-size: medium;
  }

  .btn-small {
    font-size: 15px;
  }

  .btn-inv {
    font-size: 18px;
  }

  .btn {
    font-size: 15px;
  }
}

@media screen and (min-width: 1280px) {
  .header {
    font-size: 64px;
  }

  .sub-header {
    font-size: 25px !important;
  }

  .caption {
    font-size: 0.75rem;
  }

  .subtitle2 {
    font-size: 0.875rem;
  }

  .address-search-icon {
    font-size: 28px;
  }

  .input-label {
    font-size: medium;
  }

  .radio-label {
    font-size: medium;
  }

  .contact-icon {
    font-size: 44px !important;
  }

  .content-text-2 {
    font-size: 20px !important;
  }

  .header-3 {
    font-size: 36px !important;
  }

  .header-2-dark {
    font-size: 24px !important;
  }

  .header-2 {
    font-size: 32px !important;
  }

  .footer-text {
    font-size: 16px !important;
  }

  .header-3-small {
    font-size: 20px !important;
  }

  .header-2-small {
    font-size: 24px !important;
  }

  .content-text-details-2 {
    font-size: 14px !important;
  }

  .content-text-details {
    font-size: 14px !important;
  }

  .content-text-3 {
    font-size: 20px !important;
  }

  .content-text {
    font-size: 20px !important;
  }

  .text-input {
    font-size: medium;
  }

  .btn-small {
    font-size: 15px;
  }

  .btn-inv {
    font-size: 18px;
  }

  .btn {
    font-size: 15px;
  }
}

.nav-link {
  color: #d4d4d4;
}

.nav-link:focus,
.nav-link:hover {
  color: #d4d4d4;
}

.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  color: #00FF99;
  background-color: transparent;
  border-color: #00FF99;
}

.nav-link.active {
  color: black !important;
  background-color: #00FF99 !important;
}




.service_acc {
  font-size: 18px;
  font-family: Verdana, sans-serif !important;
  /* font-weight: lighter !important; */
  text-align: left;
  padding: 8px;
  color: rgb(153, 153, 153) !important;
}



.pp-input-2 {
  border-radius: 2px;
  border: none;
  padding: 4px 8px;
  color: white;
  background: rgb(41, 41, 41);
  margin: 4px;
}

input.pp-input-2:focus-visible {
  border: none;
  outline: none;
}

.pp-input-2:disabled {
  border-radius: 2px;
  border: none;
  padding: 4px 8px;
  color: white;
  background: transparent;
}

.pp-tbl-tr {
  cursor: pointer;
}

.pp-tbl-tr:hover {
  background-color: #1eb679;
}

.modal-content {
  background-color: transparent;
}


@font-face {
  font-family: "Head-Font";
  src: url("./fonts/APERTURE/aperture2.0-webfont.ttf") format('truetype');
}

@font-face {
  font-family: "Sub-Font";
  src: url("./fonts/Montserrat/static/Montserrat-Regular.ttf") format('truetype');
}

@font-face {
  font-family: "BD-Head-Font";
  src: url("./fonts/BeautifullyDelicious/BDSans-Regular.ttf") format('truetype');
}

@font-face {
  font-family: "BD-Sub-Font";
  src: url("./fonts/BeautifullyDelicious/BDSans-Thin.ttf") format('truetype');
}
@font-face {
  font-family: "BD-Bold-Font";
  src: url("./fonts/BeautifullyDelicious/BDSans-Bold.ttf") format('truetype');
}


.head_font{
  font-family:"BD-Head-Font";
  color: #00FF99;
  text-transform: uppercase;
  font-weight: bolder;
  background: linear-gradient(135deg, #00FF99, #94e6ff);
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
}

.head_font2{
  font-family:"BD-Head-Font";
}

.sub_font{
  font-family:"BD-Sub-Font";
}
.sub_font_bold{
  font-family:"BD-Bold-Font";
}

.montserrat{
  font-family: "Sub-Font";
}

.navbar-toggler:focus{
  box-shadow: none;
}
.navbar-toggler{
  border-radius: 0px;
}
.nav-link a{
  color: rgb(0, 255, 153);
}
.nav-link a:hover{
  color: white;
}
.offcanvas{
  background-color: black;
}
.offcanvas-title{
  color: #00FF99;
}




.embla {
  overflow: hidden;
}
.embla__container {
  display: flex;
}
.embla__slide {
  flex: 0 0 100%;
  min-width: 0;
}
