body {
    background-color: black;
}

.sign-in-logo-container {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.sign-in-logo-container>img {
    width: 85%;
}

.sign-in-container {
    background-color: black;
}

.sign-in-user-img-container {
    z-index: 2;
    position: relative;
    width: fit-content;
    background-color: #ffffff;
    height: fit-content;
    margin: auto auto -75px;
    border-radius: 50%;
    padding: 20px;
}

.sign-in-panel2 {
    background-color: #333;
    width: 100%;
    height: 100%;
    border-radius: 8px;
    margin-bottom: 10px;
    padding-left: 32px;
    padding-right: 32px;
    padding-top: 20px;
}


.sign-in-panel {
    background-color: #333;
    width: 100%;
    height: 100%;
    border-radius: 8px;
    padding: 32px;
}

.sign-in-header {
    text-align: center;
    color: #00FF99;
    font-weight: normal
}

.sign-in-label {
    line-height: 2.0;
    color: #ffffff;
}

.pp-input {
    border-radius: 2px;
    border: none;
}

input.pp-input:focus-visible {
    border: none;
    outline: none;
}

input[type=submit] {
    padding: 4px 10px;
    border: none;
    border-radius: 2px;
    background-color: #00FF99;
    color: #000000;
    box-shadow: 0 1px 2px 0 #000000;
}

input[type=submit]:hover {
    background-color: #0b5c3c;
    color: #000000;
}