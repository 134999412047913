.large_circle, .small_circle{
width: 30px;
height: 30px;
border-radius: 50%;
border:1px solid #00ff99;
position: fixed;
z-index: 1000;
pointer-events: none;
}

.small_circle{
    width: 8px;
    height: 8px;
    background-color:#00ff99;
    z-index: 1000;
    pointer-events: none;
}





